<template>
  <pagamento-dados-nivel1-agrupado
    :visao="visao"
    ref="nivel_1_cards"
    @Pagamento__SelecionaItemNivel1="selectItem">
  </pagamento-dados-nivel1-agrupado>
</template>

<script>
import PagamentoDadosNivel1Agrupado from './pagamento/PagamentoDadosNivel1Agrupado';

export default {
  data() {
    return {
      visao: 'Status',
    };
  },
  components: {
    PagamentoDadosNivel1Agrupado,
  },
  methods: {
    selectItem(item, router, visaoSelecionada) {
      if (visaoSelecionada === 'Status') {
        this.$router.push({
          name: router,
          params: {
            status: item.status,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Contrato') {
        this.$router.push({
          name: router,
          params: {
            idContrato: item.idContrato,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      }
    },
  },
  beforeMount() {
    this.visao = this.$route.params.visao ? this.$route.params.visao : 'Status';
  },
};
</script>
