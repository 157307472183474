<template>
  <div>
    <v-container fluid px-0 py-2 class="Container_Nivel1_Pagamento">
      <v-row justify="start" class="mx-0">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            :label="`${$tc('label.pesquisar', 1)}`"
            single-line
            hide-details
            clearable
            @input="pesquisar"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar"
                icon
                class="mx-0 pt-1"
                v-on="on"
                @click="abrirModalImportacao">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-0">
        <v-container fluid class="Barra_Navegacao elevation-1 mt-2 pa-0 mx-0">
          <v-row align="center" justify="center" class="mx-0">
            <v-bottom-navigation
              v-model="visaoSelecionada"
              height="50px"
              class="elevation-0"
              color="transparent"
              @change="recarregaBusca">
              <v-col cols="12" sm="3" v-for="aba in abas" :key="aba.valor" style="background-color: white; text-align: center;" class="pt-1">
                <v-btn text small style="color: #0086ff" :value="aba.valor">
                  <span>{{ aba.titulo }}</span>
                  <v-icon>{{ aba.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-bottom-navigation>
          </v-row>
        </v-container>

        <v-container fluid grid-list-md class="Container_Cards" v-if="listaCards.length">
          <v-data-iterator
            :items="listaCards"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start" class="mx-0">
                <v-col
                  v-for="item in props.items"
                  :key="item.titulo"
                  cols="12"
                  sm="4"
                 md="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Pagamento" @click.prevent="selectItem(item)">
                      <v-card-title class="Card_Title pa-2 justify-center" >
                        <div style="width: 100%;" class="text-center mb-0">
                          {{ item.titulo }}
                        </div>
                      </v-card-title>
                      <v-card-text class="Card_Content py-0 headline font-weight-light">
                        <v-row justify="center" align="center" v-if="isVisaoContrato">{{ getMoney(item.totalPagamentosPorContrato) }}</v-row>
                        <v-row justify="center" align="center" v-bind:style="{color: corValorPercentual(item)}" >
                          {{ getPercent(item.valorPercentual) }}
                        <v-col align="end" style="position: absolute;" v-if="isVisaoContrato">
                          <v-tooltip bottom align="end" v-if="mostrarBotaoExportacao">
                            <template v-slot:activator="{ on }">
                              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click.stop="exportar(item)">
                                <v-icon>cloud_download</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('label.exportar') }}</span>
                          </v-tooltip>
                        </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="Card_Actions">
                        <v-row class="text-xs-center" align="end" v-if="isVisaoStatus">
                          <v-col cols="12" sm="10" class="py-0">
                            {{ $tc('label.total_pagamento', 2) }}: <br />
                            {{ getMoney(item.totalPagamentos) }}
                          </v-col>
                          <v-tooltip bottom v-if="mostrarBotaoExportacao">
                            <template v-slot:activator="{ on }">
                              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click.stop="exportar(item)">
                                <v-icon>cloud_download</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('label.exportar') }}</span>
                          </v-tooltip>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <modal-importacao-pagamento
      ref="modalImportacaoPagamento"
      @RECARREGAR_PAGAMENTO="buscar">
    </modal-importacao-pagamento>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import {
  listarPagamentosPorContrato,
  listarPagamentosPorStatus,
} from '../../../common/resources/liquidacao/pagamento';
import exportacao from '../../../common/functions/exportacao';
import ModalImportacaoPagamento from './ModalImportacaoPagamento';

export default {
  name: 'PagamentoDadosNivel1Agrupado',
  components: {
    ModalImportacaoPagamento,
  },
  props: {
    visao: String,
  },
  data() {
    return {
      pagamentoResource: this.$api.pagamento(this.$resource),

      pesquisaCards: '',
      showNav: true,
      mensagemConfirmacao: '',
      visaoSelecionada: 'Status',
      listaCards: [],
      listaCardsStatus: [],
      filtrosAplicados: this.filtros,
      rowsPerPageItems: [12],
      totalPage: 0,
      pagination: {
        itemsPerPage: 12,
      },
      buscando: false,
      esperarDigitar: false,
      abas: [
        {
          titulo: this.$tc('label.status', 1),
          valor: 'Status',
          icon: 'update',
        },
        {
          titulo: this.$tc('label.contrato', 1),
          valor: 'Contrato',
          icon: 'store',
        },
      ],
      bloquearBotaoExportacao: false,
      mostrarBotaoExportacao: false,
    };
  },
  watch: {
    filtroLiquidacaoContrato: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoContrato;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoContrato) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroLiquidacaoContratoNivel1',
      'filtroLiquidacaoContrato',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('CTRT_PGTO_CRUD', [
      'canEdit',
    ]),
    isVisaoStatus() {
      return this.visaoSelecionada === 'Status';
    },
    isVisaoContrato() {
      return this.visaoSelecionada === 'Contrato';
    },
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoContratoNivel1',
    ]),
    recarregaBusca() {
      this.pagination.page = 1;
      this.listaCards = [];
      this.buscar();
    },
    corValorPercentual(item) {
      if (this.isVisaoStatus) {
        if (item.status === 'APROVADO') {
          return 'rgb(30, 136, 229)';
        } if (item.status === 'AGUARDANDO_PAGAMENTO') {
          return 'rgb(230, 126, 34)';
        } if (item.status === 'LIQUIDADO') {
          return 'rgb(26, 188, 156)';
        } if (item.status === 'BLOQUEADO') {
          return 'rgb(241, 196, 15)';
        } if (item.status === 'CANCELADO') {
          return 'rgb(231, 76, 60)';
        }
      } else if (this.isVisaoContrato) {
        if (item.valorPercentual === 0) {
          return 'rgb(149, 165, 166)';
        } if (item.valorPercentual > 0 && item.valorPercentual < 100) {
          return 'rgb(241, 196, 15)';
        } if (item.valorPercentual === 100) {
          return 'rgb(26, 188, 156)';
        }
      }

      return '#757575';
    },
    selectItem(item) {
      if (this.isVisaoStatus) {
        this.$emit('Pagamento__SelecionaItemNivel1', item, 'listarPagamentosContratoNivel2Status', this.visaoSelecionada);
      } else {
        this.$emit('Pagamento__SelecionaItemNivel1', item, 'listarPagamentosContratoNivel2Contrato', this.visaoSelecionada);
      }
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 1000);
    },
    pesquisar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        if (this.isVisaoStatus) {
          this.filtrarStatus();
        } else {
          this.buscar();
        }
      }, 1000);
    },
    buscar() {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        visao: 'visao_contrato',
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      let promise = null;

      if (this.isVisaoStatus) {
        promise = listarPagamentosPorStatus(params, this.$resource);
      } else if (this.isVisaoContrato) {
        promise = listarPagamentosPorContrato(params, this.$resource);
      }

      promise
        .then((response) => {
          if (this.isVisaoStatus) {
            this.listaCards = this.preencherListaStatus(response.data.resposta);
          } else {
            this.listaCards = response.data.resposta;
          }
          this.rowsPerPageItems = [12, 16, 20, 24, 28, 32, 36, 40];
          this.totalPage = response.data.quantidadeRegistrosPagina;
          setTimeout(this.finalizandoBusca);
          this.preencherFiltrosNivel1Vuex();
        })
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    preencherListaStatus(lista) {
      this.listaCardsStatus = [];

      lista.forEach((card) => {
        card.titulo = this.$tc(`status_entidade_pagamento.${card.status}`.toLowerCase(), 1);
        this.listaCardsStatus.push(card);
      });

      return this.listaCardsStatus;
    },
    filtrarStatus() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.listaCards = !this.pesquisaCards ? this.listaCardsStatus
          : this.listaCardsStatus
            .filter((el) => el.titulo.toLowerCase().includes(this.pesquisaCards.toLowerCase()));
      }, 1000);
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoContratoNivel1({
        pagina,
        quantidatePorPagina,
      });
    },
    preencherFiltrosNivel1() {
      this.pagination.page = this.filtroLiquidacaoContratoNivel1.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoContratoNivel1.quantidatePorPagina;
    },
    exportar(item) {
      let params = {
        ...this.filtrosAplicados,
        filtro: '',
      };
      if (this.isVisaoStatus) {
        params = {
          ...params,
          status: item.status,
        };
      } else if (this.isVisaoContrato) {
        params = {
          ...params,
          id_contrato: item.idContrato,
        };
      }
      const cb = () => { this.bloquearBotaoExportacao = false; };
      exportacao.exportar(cb, 'pagamentos', this, params, 5000, 0, 'liquidacao');
    },
    abrirModalImportacao() {
      if (this.$refs.modalImportacaoPagamento) {
        this.$refs.modalImportacaoPagamento.toggle();
      }
    },
  },
  mounted() {
    this.visaoSelecionada = this.visao;
    if (this.filtroLiquidacaoContratoNivel1) {
      this.preencherFiltrosNivel1();
    }

    if (this.filtroLiquidacaoContrato) {
      this.filtrosAplicados = this.filtroLiquidacaoContrato;
      this.buscar();
    }

    if (this.getAllRoles) {
      this.mostrarBotaoExportacao = this.getAllRoles
        .filter((el) => el.indexOf('REL_ACAO_PGTO') !== -1).length > 0;
    }
  },
};
</script>

<style>
  .clickable {
    cursor: pointer;
  }
  .Container_Nivel1_Pagamento .Card_Pagamento {
    min-height: 146px !important;
    height: 100%;
  }
  .Container_Nivel1_Pagamento .Card_Title, .Container_Nivel1_Pagamento .Card_Content {
    color: #757575;
  }
</style>
